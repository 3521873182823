import Inputmask from 'inputmask';
import Clipboard from 'clipboard';

document.addEventListener('turbolinks:load', () => {
  const selector = document.querySelectorAll('.money-mask');
  const centsSelector = document.querySelectorAll('.money-mask-cents');

  Inputmask({
    alias: 'currency',
    rightAlign: false,
    allowMinus: false
  }).mask(centsSelector);

  Inputmask({
    alias: 'currency',
    digits: 0,
    rightAlign: false,
    numericInput: true,
    placeholder: '',
    allowMinus: false
  }).mask(selector);
});

document.addEventListener('turbolinks:load', () => {
  const copySupported = Clipboard.isSupported();
  if (!copySupported) return;

  document.querySelectorAll('[data-js-copy-family-link]').forEach((el) => {
    el.classList.remove('d-none');
    el.addEventListener('click', (ev) => {
      ev.preventDefault();
    });
    new Clipboard(el, {
      text: () => document.querySelector('#slug_link').value
    });
  });
});
